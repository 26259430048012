import React from "react"
import Navbar from "src/components/navbar"
import Layout from "src/components/layout"
import Jumbotron from "src/components/jumbotron"
import Section from "src/components/section"
import { Grid } from "@material-ui/core"
import styles from "src/styles/pages/sport.module.scss"
import sectionStyles from "src/styles/components/section.module.scss"
import cx from "classnames"
import SEO from "src/components/seo"
import SportLogo from "src/components/sport-logo"

export default function Sport() {
  return (
    <Layout className={styles.root}>
      <Navbar />
      <Jumbotron
        section={{
          title: "Blended Learning",
          description:
            "Digitale Unterstützung für Ihre sportpsychologische Arbeit",
          cta_text: "Angebot anfordern",
          cta_link: "mailto:lukas.stenzel@mindance.de",
        }}
        backgroundImage="/assets/img/sport/jumbotron-bg.png"
      />
      <SEO
        title="Sport Page"
        description="Sportler erhalten jetzt Zugriff auf das Angebot von Mindance. Fordern Sie dazu Ihr Angebot für die Mindance-App an, um alle Vorteile zu nutzen."
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
      />
      <Grid
        container
        spacing={2}
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.missionSection
        )}
      >
        <Grid item xs={12} md={6}>
          <h1>Kooperation</h1>
          <p>
            Unser Ziel ist es, Sportpsychologen:innen in ihrer täglichen Arbeit
            mit Athleten:innen zu unterstützen, so dass sie ihren Athleten:innen
            die passende Unterstützung zur richtigen Zeit bieten können.
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <h1>Unser gemeinsamer Blended Learning Ansatz</h1>
          <p>
            Eine Symbiose aus Technologie und Ihrer persönlichen Betreuung vor
            Ort:
            <ul>
              <li>Persönliche Betreuung durch Sie vor Ort</li>
              <li>Mindance App</li>
              <li>Dashboard</li>
            </ul>
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.imageContainer
        )}
      >
        <Grid container spacing={2} direction="row">
          <Grid item xs={4} md={4} sm={6}>
            <div className={styles.container}>
              <h3>
                I. <br /> Persönliche Betreuung
              </h3>
              <img
                className={styles.iconImage}
                src="/assets/img/sport/personal.png"
              />
            </div>
          </Grid>
          <Grid item xs={4} md={4} sm={6}>
            <div className={styles.container}>
              <h3>
                II. <br /> Mindance App <br />
              </h3>
              <img
                className={styles.iconImage}
                src="/assets/img/sport/app.svg"
              />
            </div>
          </Grid>
          <Grid item xs={4} md={4} sm={6}>
            <div className={styles.container}>
              <h3>
                III. <br /> Mindance Dashboard
              </h3>
              <img
                className={styles.iconImage}
                src="/assets/img/sport/dashboard.svg"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.section
        )}
      >
        <Grid container spacing={2} className={styles.greenbg}>
          <Grid item xs={12}>
            <h1>Persönliche Betreuung</h1>
            <p className={styles.featureDescription}>
              Eine Unterstützung für Ihre persönliche Betreuung vor Ort
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              Sie werden in die Mindance App und das dazugehörige Dashboard
              eingeführt, so dass Sie die verschiedenen Funktionen optimal auf
              die Bedürfnisse Ihrer Athleten:innen vor Ort abstimmen können.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              <ul>
                <li>Gemeinsame Abstimmung der App Inhalte</li>
                <li>Gezielte Empfehlung von Übungen</li>
                <li>Abgestimmte Push-Nachrichten</li>
                <li>Technischer Support</li>
              </ul>
            </p>
          </Grid>
        </Grid>
      </div>
      <div
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.section
        )}
      >
        <Grid container spacing={2} className={styles.greenbg}>
          <Grid item xs={12}>
            <h1>Mindance App</h1>
            <p className={styles.featureDescription}>
              Die Inhalte der App werden auf Ihre Bedürfnisse abgestimmt, so
              dass Sie die App optimal in Ihre Arbeit integrieren können.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              Unsere wissenschaftliche Datenbank enthält fundierte
              sportpsychologische Trainingstechniken, zum Beispiel:
              <ul>
                <li>Progressive Muskelentspannung</li>
                <li>Autogenes Training</li>
                <li>Visualisierungen</li>
                <li>Selbstgesprächsregulation</li>
                <li>Mentale Wettkampfvorbereitung</li>
                <li>Und vieles mehr!</li>
              </ul>
              Aus unser breiten Datenbank finden wir gemeinsam das App Set-up,
              dass zu Ihrer Arbeit passt.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              Die mentalen Trainingstechniken können in der Mindance-App über
              audio-geführte Übungen, zeitlich flexibel und ortsunabhängig
              durchgeführt werden.
            </p>
            <img src="/assets/img/sport/screen_1.png" />
          </Grid>
        </Grid>
      </div>
      <div
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.section
        )}
      >
        <Grid container spacing={2} className={styles.greenbg}>
          <Grid item xs={12}>
            <h1>Mindance Dashboard</h1>
            <p className={styles.featureDescription}>
              Interventionen zum richtigen Zeitpunkt - mit dem Erholungs- und
              Belastungsscreening von Mindance
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              className={styles.columnImage}
              src="/assets/img/sport/screen_2.png"
            />
            <p>
              Die Athleten:innen beantworten über die App kurze Fragen zur
              psychologischen Verfassung, z.B. Stimmung, Schlafqualität etc.
            </p>
          </Grid>
          <img
            className={styles.transitionArrow}
            src="/assets/img/sport/arrow.svg"
          />
          <Grid item xs={12} md={6}>
            <img
              className={styles.columnImage}
              src="/assets/img/sport/screen_3.png"
            />
            <p>
              Die Ergebnisse können Sie aufbereitet über Ihr persönliches
              Dashboard einsehen, um so Interventionen zur richtigen Zeit
              durchzuführen und langfristige strategische Entscheidungen zu
              treffen.
            </p>
          </Grid>
        </Grid>
      </div>
      <Section
        section={{
          title:
            "Investieren Sie jetzt in die psychische Gesundheit Ihrer Sportlerinnen und Sportler!",
          cta_text: "Angebot anfordern",
          cta_link: "mailto:lukas.stenzel@mindance.de",
        }}
      />

      <SportLogo />
      <div
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.profileSection
        )}
      >
        <Grid container spacing={2} className={styles.greenbg}>
          <Grid item xs={12}>
            <h4>
              Sie sind sich nicht sicher, ob Mindance das Richtige ist? Nehmen
              Sie Kontakt zu uns auf und wir klären alle Ihre offenen Fragen!
            </h4>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={styles.profile}>
              <img
                className={styles.columnImage}
                src="/assets/img/sport/lukas.png"
              />
              <h5>Lukas Stenzel</h5>
              <span>M.Sc. Psychologie</span>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              Lukas Stenzel ist ausgebildeter Sportpsychologe (asp-Curriculum),
              promoviert berufsbegleitend im Bereich der angewandten
              Sportpsychologie an der Universität Halle und ist für die
              wissenschaftlich fundierten Inhalte bei Mindance verantwortlich.
            </p>
            <p>
              <a href="mailto:lukas.stenzel@mindance.de">
                lukas.stenzel@mindance.de
              </a>
            </p>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}
