import React from "react"
import sectionStyles from "src/styles/components/section.module.scss"
import cx from "classnames"
import styles from "src/styles/pages/sport.module.scss"
import { Grid } from "@material-ui/core"

export default function SportLogo() {
  return (
    <div
      className={cx(
        sectionStyles.root,
        sectionStyles.boundedWidth,
        styles.section,
        styles.prize
      )}
    >
      <h1 className={styles.preTitle}>Gewinner des Innovationspreises:</h1>
      <h1>Performance & Health im Leistungssport</h1>
      <p>
        <a href="https://www.dfb.de/news/detail/sportpsychologisches-training-dfb-testet-mindance-app-225917/">
          Mehr infos
        </a>
      </p>
      <img src="/assets/img/sport/prize.png" />
      <div
        className={cx(
          sectionStyles.root,
          sectionStyles.boundedWidth,
          styles.root,
          styles.section,
          styles.partners
        )}
      >
        <h1>Verschiedene Kunden aus dem Sport Bereich vertrauen uns</h1>
        <Grid container spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={3} md={3}>
              <img src="/assets/img/sport/Eintracht_Frankfurt_Logo.png" />
            </Grid>
            <Grid item xs={3} md={3}>
              <img src="/assets/img/sport/1-FC-Union-Berlin.png" />
            </Grid>
            <Grid item xs={3} md={3}>
              <img src="/assets/img/sport/Hannover_96_Logo.png" />
            </Grid>
            <Grid item xs={3} md={3}>
              <img src="/assets/img/sport/deutscher-fechter-bund.png" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4} md={4}>
              <img src="/assets/img/sport/die-sportpsychologen-logo-500.png" />
            </Grid>
            <Grid item xs={4} md={4}>
              <img src="/assets/img/sport/mentaltastic.jpg" />
            </Grid>
            <Grid item xs={4} md={4}>
              <img src="/assets/img/sport/Hessen.jpg" />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
